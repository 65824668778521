.TsAndCs * {
  margin: 0;
  font-weight: lighter;
}
.TsAndCs__back-btn {
    width: 13vw;
    display: block;
    margin-top: 5vw !important;
    cursor: pointer;
  }

  .TsAndCs h1 {
    font-size: 0.85em;font-weight: bold;
  }
  .TsAndCs h2 {
    font-size: 0.65em;font-weight: bold;
  }
  .TsAndCs p {
    font-size: 0.5em;
    line-height: 1.5em;
  }
@media (min-width: 500px) {
  .TsAndCs__back-btn {
    width: 4vw;
    display: block;
    margin-top: 5vw !important;
  }

  .TsAndCs h1 {
    font-size: 1.25em;font-weight: bold;
  }
  .TsAndCs h2 {
    font-size: 0.75em;font-weight: bold;
  }
  .TsAndCs p {
    font-size: 0.6em;
    line-height: 1.5em;
  }
}
