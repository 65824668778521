.CookiePolicy * {
  font-weight: lighter;
}
.CookiePolicy__back-btn {
  width: 13vw;
  display: block;
  margin-top: 5vw !important;
  cursor: pointer;
}

.CookiePolicy h1 {
  font-weight: bold;

  font-size: 0.85em;
}
.CookiePolicy h2 {
  font-size: 0.65em;
  font-weight: bold;
}
.CookiePolicy p {
  font-size: 0.5em;
  line-height: 1.5em;
}
.CookiePolicy span {
  font-weight: bold;
}
@media (min-width: 500px) {
  .CookiePolicy__back-btn {
    width: 4vw;
    display: block;
    margin-top: 5vw !important;
  }

  .CookiePolicy h1 {
    font-size: 1.25em;
  }
  .CookiePolicy h2 {
    font-size: 0.75em;
  }
  .CookiePolicy p {
    font-size: 0.6em;
    line-height: 1.5em;
  }
}
