.PrivacyPolicy * {
  margin: 0;
  font-weight: lighter;
}
.PrivacyPolicy__back-btn {
  width: 13vw;
  display: block;
  margin-top: 5vw !important;
  cursor: pointer;
}

.PrivacyPolicy h1 {
  font-weight: bold;

  font-size: 0.85em;
}
.PrivacyPolicy h2 {
  font-size: 0.65em;
  font-weight: bold;
}
.PrivacyPolicy p {
  font-size: 0.5em;
  line-height: 1.5em;
}
.PrivacyPolicy span {
  font-weight: bold;
}
@media (min-width: 500px) {
  .PrivacyPolicy__back-btn {
    width: 4vw;
    display: block;
    margin-top: 5vw !important;
  }

  .PrivacyPolicy h1 {
    font-size: 1.25em;
    font-weight: bold;
  }
  .PrivacyPolicy h2 {
    font-size: 0.75em;
    font-weight: bold;
  }
  .PrivacyPolicy p {
    font-size: 0.6em;
    line-height: 1.5em;
  }
}
