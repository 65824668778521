.MyBrandStats__IpoProgressContainer {
  position: relative;
  display: block;
  margin: 0 auto;
  width: fit-content;
  max-width: 1200px;
}
.MyBrandStats__IpoProgressSliderContainer {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: end;
  position: relative;
  margin: 0 auto;
  padding-top: 0px;
}
.MyBrandStats__IpoProgressSlider {
    position: relative;
    display: flex;
    align-items: center;
  width: 580px;
  border: 1px solid white;
  border-radius: 20px;
  height: 17px;
  margin: 0;
}
.Estimate__WorthRangesContainer {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-evenly;
    align-items: end;
    position: relative;
    margin: 30px auto 0;
}